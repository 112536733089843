<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit Department</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="userCreateForm"
    >
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="departmentCreateForm">
        <div class="col-sm-12 col-md-6">
          <text-input name="Name" v-model="formData.name" label="Name" validate="required" />
        </div>
        <div class="col-sm-12 col-md-6">
          <text-input name="MarketingSource" v-model="formData.marketing_source" label="Marketing Source"
            tooltip="The Marketing Source is the origin of where the contact found your company (How did you hear about us?). We will send this information to zaiper along with contact data. Leave blank if you don't use this."
          />
        </div>
        <div v-if="widgetTypePhone" class="col-sm-12 col-md-12">
          <div class="row mt-5">
            <div class="col-md-9">
              <p class="mr-5">
                <strong>Voice Agents</strong><br />
                <small>List of agents to accept the voice call.</small>
              </p>
            </div>
            <div class="col-md-3">
              <button type="button" class="float-right btn btn-primary"
                @click="onAddNewAgent('voice')">
                <i class="fa fa-plus" /> Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <table class="table table-agents">
                <tbody>
                  <tr v-for="(item, index) in formData.voiceAgents" :key="index">
                    <td width="30%"><b>{{item.full_name}}</b><br/><small>{{ item.email }}</small></td>
                    <td width="10%">
                      <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                      <span v-else class="badge badge-default">User</span>
                    </td>
                    <td width="30%">{{ item.phone }}</td>
                    <td width="20%" class="notification-td">
                      <span v-if="item.call || item.mail_call" v-b-tooltip.hover title="Call Notification"  class="text-primary tooltip-icon"><i class="fa fa-phone"></i></span>
                    </td>
                    <td width="10%">
                      <button type="button" class="btn btn-danger btn-sm" @click="deleteAgent('voice', index)">
                        <i class="fa fa-trash-o" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="widgetTypeText" class="col-sm-12 col-md-12">
          <div class="row mt-5">
            <div class="col-md-9">
              <p class="mr-5">
                <strong>SMS Agents</strong><br />
                <small>List of agents to accept manage sms requests.</small>
              </p>
            </div>
            <div class="col-md-3">
              <button type="button" class="float-right btn btn-primary"
                @click="onAddNewAgent('sms')">
                <i class="fa fa-plus" /> Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3">
              <table class="table table-agents">
                <tbody>
                  <tr v-for="(item, index) in formData.smsAgents" :key="index">
                    <td width="30%"><b>{{item.full_name}}</b><br/><small>{{ item.email }}</small></td>
                    <td width="10%">
                      <span v-if="item.role === 'BusinessAdmin'" class="badge badge-primary">Owner</span>
                      <span v-else class="badge badge-default">User</span>
                    </td>
                    <td width="30%">{{ item.phone }}</td>
                    <td width="20%" class="notification-td">
                      <span v-if="item.sms || item.mail_sms" v-b-tooltip.hover title="SMS Notification"  class="text-primary tooltip-icon"><i class="fa fa-comments"></i></span>
                    </td>
                    <td width="10%">
                      <button type="button" class="btn btn-danger btn-sm" @click="deleteAgent('sms', index)">
                        <i class="fa fa-trash-o" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <p class="mr-5">
            <strong>Tags</strong><br />
            <small>List of tags</small>
          </p>
          <div>
            <tag-selector
              :show-label="false"
              v-model="formData.tags"
            ></tag-selector>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <router-link class="btn btn-primary btn-danger mr-2" :to="{name: 'business.departments.index'}">
              <span>Back</span>
            </router-link>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <vuestic-modal
      :isOpen="isOpenModalAdd"
      @ok="handleAddAgent"
      @cancel="closeAddModal"
      okText="Add"
      :okDisabled="!newAgent"
      cancelText="Cancel"
      :closeOnOk="false"
    >
      <span slot="title" class="text-primary"><b>Add New Agent</b></span>
      <div class="row">
        <div class="col-md-12">
          <span class="">To add additional agents you must first create them as users, click <router-link class="text-primary font-weight-bold" :to="{name: 'business.users.index'}">HERE</router-link> to add additional users.</span>
        </div>
        <div class="col-md-12 mt-3">
          <multiselect
            v-model="newAgent"
            :options="userList"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Business User"
            label="label"
            track-by="label"
            :preselect-first="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
  import Vue from 'vue';
  import components from "../../common/tables/comps";
  import { mapState } from 'vuex';
  import TagSelector from '../../common/TagSelector'

  export default {
    components: { 
      TagSelector
    },
    data() {
      return {
        loading: false,
        users: [],
        formData: {
          name: '',
          marketing_source: '',
          active: false,
          voiceAgents: [],
          smsAgents: [],
          tags: [],
        },
        department: {
          voiceAgents: [],
          smsAgents: []
        },
        addType: undefined,
        isOpenModalAdd: false,
        newAgent: undefined,
      };
    },

    computed: {
      ...mapState('department', {
        fetching: 'fetching',
        didFetch: 'didFetch',
        processing: 'processing',
      }),
      userList() {
        return this.users.map(item => {
          return  {
            ...item,
            label: item.full_name + ' ( ' + item.phone + ' ) ',
          }
        })
      },
      
      widgetTypePhone() {
        return this.$store.getters['auth/widgetTypePhone']
      },

      widgetTypeText() {
        return this.$store.getters['auth/widgetTypeText']
      },

      businessOwner() {
        return this.$store.getters['auth/businessOwner']
      },

      business() {
        return this.$store.state.auth.user.business
      }
    },
    mounted() {
      this.loadUsers();

      if (this.$route.params.id) {
        const id = this.$route.params.id
        this.loadData(id);
      } else {
        this.redirectOnError()
      }
    },
    methods: {
      redirectOnError() {
        this.$router.push({ name: 'business.departments.index' });
      },

      loadData(id) {
        this.loading = true;
        this.$store
          .dispatch('department/get', id)
          .then((res) => {
            this.loading = false;
            this.initData(res.data);
          })
          .catch((err) => {
            this.loading = false;
            this.redirectOnError()
          })
      },

      initData(data) {
        this.department = data
        this.formData = 
        { 
          ...this.formData,
          id: data.id,
          name: data.name,
          marketing_source: data.marketing_source,
          active: data.active,
          voiceAgents: data.voiceAgents.map(item => {
            return {
              ...item,
              label: item.full_name + ' ( ' + item.phone + ' ) ',
            }
          }),
          smsAgents: data.smsAgents.map(item => {
            return {
              ...item,
              label: item.full_name + ' ( ' + item.phone + ' ) ',
            }
          }),
          tags: data.tags
        }
      },


      loadUsers() {
        this.$store
          .dispatch('businessUser/agents')
          .then((data) => {
            this.users = data;
          })
          .catch((err) => {
          })
      },

      onAddNewAgent(type = 'voice') 
      {
        this.newAgent = undefined
        this.addType = type
        this.isOpenModalAdd = true
      },

      closeAddModal() {
        this.isOpenModalAdd = false
      },

      handleAddAgent() {
        let list = this.addType === 'voice' ? this.formData.voiceAgents : this.formData.smsAgents
        if (list.find(item => item.id === this.newAgent.id))
        {
          Vue.$toast.open({
            message: 'This agent already exists in list, Please select another agent.',
            type: 'error',
          })
          return false;
        }
        list.push(this.newAgent)
        this.closeAddModal();
      },

      deleteAgent(type = 'voice', index = 0)
      {
        if (type === 'voice')
        {
          this.formData.voiceAgents.splice(index,1)
        } else {
          this.formData.smsAgents.splice(index,1)
        }
      },

      checkAgents(type = 'voice')
      {
        let result = false;
        const list = type === 'voice' ? this.formData.voiceAgents : this.formData.smsAgents;
        list.forEach((item) => {
          if (item.id)
          {
            result = true
            return true
          }
        });
        return result
      },

      onFormSubmit() {
        if (this.widgetTypePhone && !this.checkAgents('voice'))
        {
          Vue.$toast.open({
            message: 'At least one voice agent is required!',
            type: 'error',
          })
          return false;
        }
        
        const param = {
          id: this.formData.id, 
          data: {
            ...this.formData,
          }
        }
        this.$store
          .dispatch('department/update', param)
          .then(() => {
          })
          .catch((err) => {
          })
      },
    },

  };
</script>

<style lang="scss" scoped>
  .departmentCreateForm {
    max-width: 1024px;
  }
</style>